import React from 'react';
import { Link } from 'react-router-dom';


function Home() {
  return (
    <div>
      <div className="content">
      <h1>Welcome to Our Service Selection Guide</h1>
      <p>Answer a few questions to get started.</p>
      <Link to="/upload">Start</Link>
            </div>
      <div className="content">
      <h1>Click here</h1>
      <Link to="/Bhole">Click Me</Link>
      </div>
   
    </div>
  );
}

export default Home;
