import React from 'react';
import { Link } from 'react-router-dom';
import StarField from './StarField';


function Bhole() {
  return (
    <div>
        <StarField numStars={150} speed={0.1} />

        <Link to="/">Start Over</Link>
    </div>
  );
}

export default Bhole;
