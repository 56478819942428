import React, { useEffect, useRef, useState } from 'react';

const StarField = ({ numStars = 300, maxSpeed = 54.3, initialCenterSize = 2 }) => {
    const canvasRef = useRef(null);
    const centerSize = useRef(initialCenterSize);
    const [starColor, setStarColor] = useState('white');

    useEffect(() => {
        const canvas = canvasRef.current;
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        const ctx = canvas.getContext('2d');
        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;

        // Initialize stars with more varied and faster angular speeds
        const stars = Array.from({ length: numStars }, () => {
            const angle = Math.random() * 2 * Math.PI;
            const radius = Math.random() * canvas.width / 2;
            return {
                angle,
                radius: Math.random() * 14.5 + 0.5,
                speed: Math.random() * (maxSpeed - 0.1) + 0.1, // Ensure minimum speed to maintain motion
                angleSpeed: (Math.random() * 0.02 + 0.01) * (Math.random() < 0.5 ? 1 : -1), // Randomize direction and speed of rotation
                distance: radius,
            };
        });

        const draw = () => {
            ctx.fillStyle = 'black';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.fillStyle = starColor;
            stars.forEach(star => {
                star.angle += star.angleSpeed;
                star.distance -= star.speed;
                star.x = centerX + star.distance * Math.cos(star.angle);
                star.y = centerY + star.distance * Math.sin(star.angle);

                // Adjust star speed and angle speed for dramatic effect as they get closer to the center
                if (star.distance < centerSize.current) {
                    star.distance = Math.random() * canvas.width / 2;
                    star.angle = Math.random() * 2 * Math.PI;
                    centerSize.current += 0.05; // Smaller increment to adjust visual growth rate
                }

                ctx.beginPath();
                ctx.arc(star.x, star.y, star.radius, 0, Math.PI * 2);
                ctx.fill();
            });

            ctx.beginPath();
            ctx.arc(centerX, centerY, centerSize.current, 0, Math.PI * 2);
            ctx.fillStyle = 'rgba(0,0,0,1)';
            ctx.fill();

            // Reset when center covers the canvas
            if (centerSize.current >= Math.sqrt(centerX * centerX + centerY * centerY)) {
                centerSize.current = initialCenterSize;
                setStarColor(`hsl(${Math.floor(Math.random() * 360)}, 100%, 50%)`);
            }

            requestAnimationFrame(draw);
        };

        draw();

        return () => {
            cancelAnimationFrame(draw);
        };
    }, [starColor, initialCenterSize, maxSpeed, numStars]);

    return <canvas ref={canvasRef} style={{ display: 'block', width: '100%', height: '100%' }} />;
};

export default StarField;


