import React from 'react';
import { Link,useNavigate } from 'react-router-dom';


function Summary() {
  const navigate = useNavigate();
  return (
    <div className="container">
      <h1>Review Your Information</h1>
      {/* Display summary of selections and uploaded file details */}
      <p>Your selections look great! Ready to submit to no where?</p>
      <button onClick={() => navigate('/Bhole')}>Submit</button>
      <p><Link to="/">Start Over</Link></p>
    </div>
  );
}

export default Summary;
