import React from 'react';
import './App.css';


import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Upload from './components/Upload';
import Summary from './components/Summary';
import Bhole from './components/Bhole';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="upload" element={<Upload />} />
        <Route path="summary" element={<Summary />} />
        <Route path="bhole" element={<Bhole />} />
      </Routes>
    </Router>
  );
}

export default App;

