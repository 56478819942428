import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';

function Upload() {
  const navigate = useNavigate();
  const onDrop = useCallback(acceptedFiles => {
    // Handle file processing here
    console.log(acceptedFiles);
    navigate('/summary');
  }, [navigate]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className="container">
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <p>Drag 'n' drop some files here, or click to select files</p>
    </div></div>
  );
}

export default Upload;
